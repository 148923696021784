.rps-workspace {
  background-color: lightblue;
  height: 100vh;
  min-width: 100vw;
  display: grid;
  grid-template-columns: 1fr 2fr 2fr;
  grid-template-rows: 10rem 1fr;
  color: whitesmoke;
}

.rps-phone {
  width: 18rem;
  height: 36rem;
  border: 0.85rem solid #192333;
  border-radius: 2.5rem;
  background-color: white;
  box-shadow: 0 0 3rem 0.5rem rgba(0, 0, 0, 0.2),
    0 1rem 1.5rem 1rem rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.rps-title,
.rps-phone-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rps-title {
  grid-row: 1;
  grid-column: 2;
}

.rps-phone-container {
  grid-column: 2;
  grid-row: 2;
  align-self: flex-start;
}

.rps-info {
  grid-column: 3;
  grid-row: 2;
}

@media (prefers-color-scheme: dark) {
  .rps-workspace {
    background-color: #2c3985;
  }

  .rps-phone {
    border-color: #0f1821;
  }
}

@media (max-width: 60rem) {
  .rps-workspace {
    grid-template-columns: 1fr 24rem 1fr;
    grid-template-rows: 10rem 3fr 1fr;
    min-height: 100vh;
  }
}

@media (max-width: 60rem) {
  .rps-workspace {
    grid-template-columns: 1fr 24rem 1fr;
  }
}

@media (max-width: 50rem) {
  .rps-info {
    margin-top: 2rem;
    margin-bottom: 2rem;
    grid-column: 2;
    grid-row: 3;
  }
}

@media (max-width: 32rem) {
  .rps-workspace {
    display: block;
    min-height: 100vh;
    height: auto;
    padding-bottom: 2rem;
  }

  .rps-info {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}
